import React, { useEffect } from "react";

export const useClickOutside = (ref: React.RefObject<HTMLElement | null>, callback: () => void) => {
  function handleClickOutside(event: MouseEvent) {
    const target = event.target as Node;

    if (ref.current && !ref.current.contains(target)) {
      callback();
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
};
