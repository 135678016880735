import styles from "./CookieModal.module.scss";

import React, { useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { motion } from "framer-motion";

import IconClose from "public/img/burger-icon-close.svg";
import IconArrow from "./icon-arrow.svg";
import { Button } from "components/shared/Button/Button";
import cn from "classnames";
import Cookies from "js-cookie";
import { useThemeContext } from "utils/themeContextWrapper";

export enum ICookieFrames {
  Main,
  Partners,
}

const variants = {
  open: {
    opacity: 1,
  },
  closed: { opacity: 0 },
};

const variantsText = {
  open: {
    height: "auto",
    marginBottom: 16,
  },
  closed: { height: "0px" },
};

const variantsArrow = {
  open: {
    rotate: 0,
  },
  closed: { rotate: 180 },
};

const CookieModal: React.FC = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState<boolean>(false);
  const [isTextHidden, setIsTextHidden] = useState<boolean>(true);

  const [cookieFrame, setCookieFrame] = useState<ICookieFrames>(ICookieFrames.Main);

  const { theme } = useThemeContext();

  useEffect(() => {
    setCookiesAccepted(
      localStorage.getItem("cookies_accepted") || Cookies?.get("cookies_accepted"),
    );
  }, []);

  //!!!!ACHTUNG!!!
  //Это ГЛОБАЛЬНОЕ подтверждение, оно унифицировано в каждом приложении
  //!!!Если меняешь здесь - поменяй во всех сервисах тоже!!!
  const handleButton = (): void => {
    const domain_name = "cryptomus.com";

    localStorage.setItem("cookies_accepted", "true");

    const expirationDate = new Date();

    expirationDate.setFullYear(expirationDate.getFullYear() + 10);

    if (window && window.location.host.includes(domain_name))
      Cookies.set("cookies_accepted", "true", { domain: domain_name, expires: expirationDate });
    else Cookies.set("cookies_accepted", "true", { expires: expirationDate });
    setCookiesAccepted(true);
  };

  const getCookieFrame = {
    [ICookieFrames.Main]: (
      <Translation>
        {(t) => (
          <>
            <div className={styles.header}>
              <div className={styles.header__title}>
                <h2>{t("cookie.Title")}</h2>
                <motion.div
                  className={styles.toggleBtn}
                  animate={isTextHidden ? "closed" : "open"}
                  initial={"closed"}
                  variants={variantsArrow}
                  transition={{ duration: 0.3 }}
                  style={{ height: 24, width: 24 }}
                >
                  <IconArrow onClick={() => setIsTextHidden(!isTextHidden)} />
                </motion.div>
              </div>
              <IconClose className={styles.close__btn} onClick={handleButton} />
            </div>

            <div className={cn(styles.text__wrapper, styles.text__wrapper_mobile)}>
              <motion.p
                animate={isTextHidden ? "closed" : "open"}
                variants={variantsText}
                transition={{ duration: 0.3 }}
                className={styles.text}
              >
                {t("cookie.Text")}
              </motion.p>
            </div>

            <div className={cn(styles.text__wrapper, styles.text__wrapper_desktop)}>
              <p className={styles.text}>{t("cookie.Text")}</p>
            </div>

            <div className={styles.button_wrapper}>
              <Button size="xs" appearance="primary" onClick={handleButton}>
                {t("cookie.Confirm")}
              </Button>
              <Button
                size="xs"
                appearance="gray"
                onClick={() => {
                  setCookieFrame(ICookieFrames.Partners);
                  setIsTextHidden(false);
                }}
              >
                {t("cookie.Partners")}
              </Button>
            </div>
          </>
        )}
      </Translation>
    ),
    [ICookieFrames.Partners]: (
      <Translation>
        {(t) => (
          <>
            <div className={styles.header}>
              <div className={styles.header__title}>
                <h2>{t("cookie.Title")}</h2>
                <motion.div
                  className={styles.toggleBtn}
                  animate={isTextHidden ? "closed" : "open"}
                  initial={"closed"}
                  variants={variantsArrow}
                  transition={{ duration: 0.3 }}
                  style={{ height: 24, width: 24 }}
                >
                  <IconArrow onClick={() => setIsTextHidden(!isTextHidden)} />
                </motion.div>
              </div>
              <IconClose className={styles.close__btn} onClick={handleButton} />
            </div>

            <div className={cn(styles.text__wrapper, styles.text__wrapper_mobile)}>
              <motion.p
                animate={isTextHidden ? "closed" : "open"}
                variants={variantsText}
                transition={{ duration: 0.3 }}
                className={styles.text}
              >
                <p className={styles.text}>
                  <a
                    className={styles.link}
                    href="https://www.nextroll.com/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    NextRoll
                  </a>
                  &nbsp;
                  {t("cookie.NextRoll")}
                </p>
              </motion.p>
            </div>

            <div className={cn(styles.text__wrapper, styles.text__wrapper_desktop)}>
              <p className={styles.text}>
                <p className={styles.text}>
                  <a
                    className={styles.link}
                    href="https://www.nextroll.com/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    NextRoll
                  </a>
                  &nbsp;
                  {t("cookie.NextRoll")}
                </p>
              </p>
            </div>

            <div className={styles.button_wrapper}>
              <Button size="xs" appearance="primary" onClick={handleButton}>
                {t("cookie.Confirm")}
              </Button>
              <Button
                size="xs"
                appearance="gray"
                onClick={() => {
                  setCookieFrame(ICookieFrames.Main);
                  setIsTextHidden(false);
                }}
              >
                {t("cookie.Back")}
              </Button>
            </div>
          </>
        )}
      </Translation>
    ),
  };

  if (cookiesAccepted) {
    return null;
  }

  return (
    <motion.div
      animate={"open"}
      exit="closed"
      initial="closed"
      variants={variants}
      transition={{ duration: 0.3 }}
      className={cn(styles.wrapper, { [styles.wrapper_dark]: theme === "dark" })}
    >
      {getCookieFrame[cookieFrame]}
    </motion.div>
  );
};

export default CookieModal;
