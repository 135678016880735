import styled from "@emotion/styled";
import { InputBase } from "@mui/material";

export const BootstrapInput = styled(InputBase)(() => ({
  visibility: "hidden",
  opacity: 0,
  width: 0,
  height: 0,
  borderRadius: "12px !important",
  right: "165px",
  position: "absolute",
  marginTop: "16px",
  "& .MuiInputBase-input": {
    borderRadius: 12,
    fontFamily: "Golos",
    letterSpacing: "-0.12px",
    position: "relative",
    // minWidth: 105,
    padding: "12px 16px",
    // '&:active': {
    //   backgroundColor: 'var(--backgroundTertiary)',
    // },
    "&:focus": {
      borderRadius: 12,
    },
  },
  "& .Mui-disabled": {
    backgroundColor: "var(--backgroundTertiary)",
    WebkitTextFillColor: "var(--primary) !important",
    opacity: "0.5 !important",
    border: "none",
  },
  "& .MuiSelect-icon": {
    right: "15px",
  },
}));

export const dropdownMenuProps = {
  disableScrollLock: true,
  // disablePortal: true,
  // anchorEl: this,

  PaperProps: {
    sx: {
      overflowY: "hidden",
      left: -32,
      "&::-webkit-scrollbar": {
        width: 12,
      },
      "&::-webkit-scrollbar-track": {
        borderWidth: "0px 1px",
        borderStyle: "solid",
        borderColor: "rgba(0, 0, 0, 0.10)",
        borderRadius: "0 12px 12px 0",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#7A7A7A",
        borderRadius: "100px",
        border: "3px solid transparent",
        backgroundClip: "content-box",
      },
      backgroundColor: "var(--secondary)",
      color: "var(--primary)",
      boxShadow: "-10px 0px 80px 0px rgba(0, 0, 0, 0.10)",
      border: "1px solid var(--border_lang)",
      borderRadius: "12px",
      padding: "0",
      maxHeight: 286,
      maxWidth: 282,
      // maxWidth: 240,
      "& .MuiMenuItem-root": {
        padding: "12px 16px",
        fontFamily: "Golos",
        letterSpacing: "-0.12px",
      },
      "& .MuiMenu-list": {
        padding: "0",
      },
      "& .Mui-selected": {
        color: "var(--primary)",
        backgroundColor: "var(--backgroundSecondary) !important",
      },
      "& .MuiCheckbox-root": {
        padding: "0 !important",
      },
      "& .MuiInputBase-root": {
        display: "none",
      },
    },
  },
};
