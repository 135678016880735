import React, { useEffect } from "react";
import styles from "./SupportButton.module.scss";
import { TgButton } from "./TgButton/TgButton";
import { ZDButton } from "./ZDButton/ZDButton";
import { useGetGeo } from "utils/hooks/useGetGeo";

const ZENDESK_KEY = "90f413a4-d768-4c77-be38-1741c3567458";

export const SupportButton = () => {
  const geoQuery = useGetGeo();

  const isVisibleTg = geoQuery?.data?.result?.geo === "RU" || geoQuery?.isError;

  const insertScript = () => {
    const script = document.createElement("script");

    script.id = "ze-snippet";
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`;
    document.body.appendChild(script);
  };

  useEffect(() => {
    const zendeskScript = document.getElementById("ze-snippet");

    if (!isVisibleTg && !zendeskScript) {
      insertScript();
    }
  }, [isVisibleTg]);

  if (geoQuery?.isLoading) {
    return null;
  }

  if (isVisibleTg) {
    return (
      <div className={styles.supportButton}>
        <TgButton />
      </div>
    );
  }

  if (!window.zE) {
    return null;
  }

  return (
    <div className={styles.supportButton}>
      <ZDButton />
    </div>
  );
};
