const splitUrl = (url: string) => {
  // Получаем часть пути из URL
  const path = url.split("/").slice(3).join("/"); // Учитываем только часть после домена

  const beforePath = path?.length ? url.split(path)[0] : url;
  const afterPath = path?.length ? url.substring(url.lastIndexOf(path)) : "";

  return { before: beforePath, after: afterPath };
};

export const getLocaleLink = (link: string, locale: string | undefined) => {
  const curLocale = locale ?? "en";
  const localeSubstr = curLocale === "en" ? "" : curLocale;
  const slash = curLocale === "en" ? "" : "/";
  const { before, after } = splitUrl(link);

  return `${before}${localeSubstr}${slash}${after}`;
};
