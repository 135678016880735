import Cookies from "js-cookie";
import { useSearchParams } from "next/navigation";

const domain_name = "cryptomus.com";

const RefCookieHandler = ({ children }: { children: JSX.Element }) => {
  const { get } = useSearchParams();

  const refQuery = get("ref");

  if (refQuery) {
    const date = new Date();

    date.setMinutes(date.getMinutes() + 30);

    if (window.location.host.includes(domain_name)) {
      Cookies.set("ref", refQuery, { domain: domain_name, expires: date });
    } else Cookies.set("ref", refQuery, { expires: date });
  }

  return children;
};

export default RefCookieHandler;
