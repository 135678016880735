import styles from "./Button.module.scss";

import React, { FC, ReactNode } from "react";
import cn from "classnames";
import Link from "next/link";

import IconLoading from "public/img/icon-loading.svg";

export interface ILinkProps {
  type: "internal" | "external" | "download";
  href: string;
  locale?: string;
  anchor?: string;
  scroll?: boolean;
}

interface IButtonProps {
  children?: ReactNode;
  appearance?: "primary" | "secondary" | "tertiary" | "gray" | "outline14" | "white";
  onClick?: (e) => void;
  white?: boolean;
  withoutBorder?: boolean;
  loading?: boolean;
  disabled?: boolean;
  size?: "xl" | "l" | "m" | "s" | "xs" | "xxl";
  fullWidth?: boolean;
  adaptivePadding?: boolean;
  width?: string;
  linkProps?: ILinkProps;
  className?: string;
}

export const Button: FC<IButtonProps> = ({
  children,
  appearance = "primary",
  onClick,
  white,
  withoutBorder,
  loading,
  disabled,
  size = "s",
  fullWidth,
  width,
  linkProps,
  className,
  adaptivePadding,
}) => {
  const button = (className?: string) => (
    <button
      disabled={loading || disabled}
      onClick={onClick}
      className={cn(styles.button, styles[size], className, {
        [styles.primary]: appearance === "primary",
        [styles.secondary]: appearance === "secondary",
        [styles.gray]: appearance === "gray",
        [styles.outline14]: appearance === "outline14",
        [styles.tertiary]: appearance === "tertiary",
        [styles.white]: white || appearance === "white",
        [styles.without_border]: withoutBorder,
        [styles.full_width]: fullWidth,
        [styles.disabled]: loading || disabled,
        [styles.adaptivePadding]: adaptivePadding,
      })}
      style={{ width }}
    >
      {loading ? (
        <div
          className={cn({
            [styles.loading]: loading,
          })}
        >
          <IconLoading />
        </div>
      ) : (
        children
      )}
    </button>
  );

  if (linkProps?.type === "external") {
    return (
      <a href={linkProps.href} target="_blank" className={className}>
        {button()}
      </a>
    );
  }

  if (linkProps?.type === "internal") {
    return (
      <Link
        href={
          linkProps?.anchor
            ? { pathname: linkProps.href, query: { anchor: linkProps.anchor } }
            : linkProps.href
        }
        as={linkProps.anchor ? linkProps.href : undefined}
        locale={linkProps.locale}
        className={className}
        scroll={linkProps.scroll ?? true}
      >
        {button()}
      </Link>
    );
  }

  if (linkProps?.type === "download") {
    return (
      <a href={linkProps.href} target="_blank" className={className} download>
        {button()}
      </a>
    );
  }

  return button(className);
};
