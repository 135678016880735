import React from "react";
import styles from "./Logo.module.scss";

import IconLogo from "public/img/logo-cryptomus.svg";
import Link from "next/link";
import { i18n } from "next-i18next";
import clsx from "clsx";

const Logo: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <Link
      href="/gateway"
      lang={i18n?.language}
      aria-label="Cryptomus main page"
      className={clsx(styles.logo, className)}
    >
      <IconLogo />
    </Link>
  );
};

export default Logo;
