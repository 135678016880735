import "styles/globals.scss";
import "styles/variables.scss";

import { NextPage } from "next";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import { appWithTranslation, useTranslation } from "next-i18next";
import React, { useState, ReactElement, ReactNode, useEffect, useRef, use } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import i18next from "i18next";

import CloseIcon from "@mui/icons-material/Close";

import { IconButton } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { SnackbarProvider } from "notistack";

import { Footer } from "components/Layout/Footer/Footer";
import { Header } from "components/Layout/Header/Header";
import ThemeContextWrapper from "utils/themeContextWrapper";
import { changeLanguage } from "../utils/changeLanguage";
import Fonts from "components/fonts";
import RefCookieHandler from "../components/RefCookieHandler/RefCookieHandler";

declare global {
  interface Window {
    zE: (
      type: "messenger" | "messenger:on" | "messenger:set",
      action: "show" | "hide" | "open" | "close" | "locale" | "unreadMessages",
      callback?: (value: number) => void,
    ) => void;
  }
}

type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export const api = axios.create({
  // baseURL: "https://api-app.xclvkbdsf234lj34s.ru/",
  baseURL: "https://api-app.cryptomus.com/",
  timeout: 5000,
  headers: {
    Authorization: `Bearer ${Cookies.get("session_id")}`,
  },
});

export const apiBTC = axios.create({
  baseURL: "https://btc-api.cryptomus.com",
  //убрать
  timeout: 5000,
  headers: {
    Authorization: `Bearer ${Cookies.get("session_id")}`,
  },
});

export const apiTRON = axios.create({
  baseURL: "https://tron-api.hopslomp123.ru/",
  //убрать
  timeout: 5000,
  headers: {
    Authorization: `Bearer ${Cookies.get("session_id")}`,
  },
});

api.interceptors.request.use(
  (config) => {
    config.headers["Language"] = i18next?.language || "en";

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  // RTL TRANSLATION SUPPORT
  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n?.dir?.();
  }, [i18n]);

  const getLayout = Component.getLayout ?? ((page) => page);
  const notistackRef = useRef<SnackbarProvider>(null);
  const router = useRouter();

  const [commentsCountList, setCommentsCountList] =
    useState<{ post_id: string; count: number }[]>();

  useEffect(() => {
    fetch("https://api-app.cryptomus.com/v1/blog/count-comments")
      .then((res) => res.json())
      .then((res) => setCommentsCountList(res.result));
  }, []);

  useEffect(() => {
    const cookieLanguage = Cookies.get("i18next");

    if (
      router?.locale &&
      i18n.options?.locales.some((lang) => window?.location?.href.includes(lang))
    )
      changeLanguage(router?.locale);
    else if (cookieLanguage && cookieLanguage !== router.locale) {
      router.push(router.asPath, router.asPath, { locale: cookieLanguage });
    }
  }, []);

  const onClickDismiss = (key) => () => {
    notistackRef?.current?.closeSnackbar(key);
  };

  const utmTags = [
    "utm_source",
    "utm_content",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "ref",
    "click_id",
    "cpa",
    "user_id",
    "client_id",
  ];

  useEffect(() => {
    utmTags?.map((tag) => {
      // eslint-disable-next-line no-prototype-builtins
      if (router?.query?.[tag]) {
        Cookies.set(tag, router?.query?.[tag], {
          expires: 7,
          domain: "cryptomus.com",
        });
      } else {
        if (Cookies.get(tag) === "undefined") {
          Cookies.remove(tag, { domain: "cryptomus.com" });
        }
      }
    });
  }, [router?.query]);

  return (
    <ThemeContextWrapper>
      <GoogleReCaptchaProvider reCaptchaKey={`${process.env.NEXT_PUBLIC_NOT_SECRET_CODE}`}>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider
            autoHideDuration={3000}
            maxSnack={3}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            ref={notistackRef}
            action={(key) => (
              <IconButton onClick={onClickDismiss(key)}>
                <CloseIcon />
              </IconButton>
            )}
          >
            <RefCookieHandler>
              <Fonts>
                <Header />
                {getLayout(<Component {...pageProps} commentsCountList={commentsCountList} />)}
                <Footer />
              </Fonts>
            </RefCookieHandler>
          </SnackbarProvider>
        </QueryClientProvider>
      </GoogleReCaptchaProvider>
    </ThemeContextWrapper>
  );
}

export default appWithTranslation(MyApp);
