import React, { useEffect, useState } from "react";

import cn from "classnames";
import debounce from "lodash/debounce";
import styles from "./ButtonUp.module.scss";
import ButtonUpIcon from "/public/img/ui/button-up.svg";

export const ButtonUp = (): JSX.Element => {
  const [visible, setIsVisible] = useState<boolean>(false);

  const toggleVisibility = debounce(
    () => setIsVisible(window.scrollY > 500 ? true : false),
    100,
  ) as EventListener;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <button
      className={cn(styles.buttonUp_container, { [styles.buttonUp_visible]: visible })}
      onClick={scrollToTop}
      aria-label={"Scroll to top button"}
    >
      <ButtonUpIcon />
    </button>
  );
};
