import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

import { api } from "pages/_app";

interface IUseAuthData {
  result: {
    expired_at: string;
    status: boolean;
    token: string;
  };
  state: number;
}

export const useAuth = () => {
  const token: string | undefined = Cookies.get("session_id");

  const { data, isSuccess, status } = useQuery(
    ["verifyToken"],
    () =>
      api.post<IUseAuthData>("v1/login/verify-token", {
        token,
      }),
    { enabled: !!token },
  );

  return { status, isAuth: isSuccess && data?.data.result.status };
};
