import React, { useEffect } from "react";
import styles from "./Footer.module.scss";

import { Translation, i18n } from "next-i18next";
import { footerList, socialLinks } from "models/Footer.data";

import cn from "classnames";

import CookieModal from "components/shared/CookieModal/CookieModal";
import { ButtonUp } from "components/shared/ButtonUp/ButtonUp";
import { Divider } from "@mui/material";

import Logo from "components/shared/Logo/Logo";
import IconLogo from "public/img/footer-logo.svg";
import Link from "next/link";
import { SupportButton } from "../../shared/SupportButton/SupportButton";

// import IconCrozdesk from "./icon-crozdesk.png";
import AppStoreBtn from "../../main/AppStoreBtn/index";
import { useRouter } from "next/router";
import CertikButton from "../../main/CertikButton";

export const Footer: React.FC = () => {
  const isLangLink = (link: string): boolean =>
    !["https", "mailto", "tg"].some((str) => link.includes(str)) && i18n?.language !== "en";

  const router = useRouter();

  const { locale, pathname } = router;

  return (
    <>
      {pathname !== "/unsubscribe" && (
        <>
          <Translation>
            {(t) => (
              <footer className={cn(styles.container, styles.footer_container)}>
                <div className={styles.footer}>
                  <div className={styles.info}>
                    <div className={styles.logo}>
                      <div className={styles.main}>
                        <Logo className={styles.logoWrapper} />
                        <p>
                          {t(
                            "footer.Cryptomus helps you receive payments from anyone in the world",
                          )}
                        </p>
                        <a
                          className={styles.trastpilot}
                          target="_blank"
                          href="https://www.trustpilot.com/review/cryptomus.com"
                          aria-label="Trastpilot review"
                        >
                          <IconLogo />
                        </a>
                        <AppStoreBtn
                          className={styles.appstore}
                          locale={locale ?? "en"}
                          theme={"light"}
                        />
                        <CertikButton className={styles.certikWrapper} />
                      </div>
                    </div>
                    <div className={styles.links}>
                      {footerList.map((item, i) => (
                        <div key={item.title} className={styles[`area-${i + 1}`]}>
                          <h3>{t(`footer.${item.title}`)}</h3>
                          {item.items.map((link) => (
                            <a
                              href={
                                isLangLink(link.link) ? `/${i18n?.language}${link.link}` : link.link
                              }
                              target={link.link.includes("https") ? "_blank" : "_self"}
                              key={link.name}
                            >
                              {t(`footer.${link.name}`)}
                            </a>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                  <Divider style={{ width: "100vw", borderColor: "#242424" }} />
                  <div className={styles.social} dir="ltr">
                    <div className={styles.links}>
                      {socialLinks.map((link) => {
                        return (
                          <a
                            href={link.href}
                            className={styles.small_icon}
                            target="_blank"
                            key={link.label}
                            aria-label={link.label}
                          >
                            {link.icon}
                          </a>
                        );
                      })}
                    </div>
                    <div className={styles.rights}>
                      <Link href="/aml">AML</Link>
                      <Link href="/privacy">Privacy policy</Link>
                      <Link href="/tos">Terms of use</Link>
                    </div>
                  </div>
                  <Divider style={{ width: "100vw", borderColor: "#242424" }} />
                  <div className={styles.juridical} dir="ltr">
                    <p>
                      XELTOX ENTERPRISES LTD. is registered as a Money service business (MSB) with
                      the Financial Transactions and Reports Analysis Centre of Canada (FINTRAC)
                      under registration number M22649585
                    </p>
                    <p>Ⓒ 2024 Cryptomus. All Rights Reserved</p>
                  </div>
                </div>
              </footer>
            )}
          </Translation>
          <CookieModal />
          <ButtonUp />

          <SupportButton />
        </>
      )}
    </>
  );
};
