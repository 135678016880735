import React from "react";
import IconTG from "public/img/ui/icon-tg-button.svg";
import styles from "./TgButton.module.scss";

export const TgButton = () => {
  return (
    <a
      className={styles.tgButton}
      href={"tg://resolve?domain=cryptomus_support_bot"}
      target="_blank"
      rel="noreferrer"
    >
      <IconTG />
    </a>
  );
};
