import { AnimatePresence, motion } from "framer-motion";
import IconArrow from "public/img/ui/icon-arrow-zendesk.svg";
import IconZendesk from "public/img/ui/icon-zendesk.svg";
import React, { useEffect, useState } from "react";
import styles from "./ZDButton.module.scss";

const variantsButton = {
  open: {
    y: 0,
    opacity: 1,
  },
  closed: (isActive) => ({
    y: isActive ? 30 : -30,
    opacity: 0,
  }),
};

export const ZDButton = () => {
  const [isZendeskOpen, setIsZendeskOpen] = useState<boolean>(false);
  const [unreadCount, setUnreadCount] = useState<number>(0);

  const handleClickZD = () => {
    document.querySelector('iframe[data-product="web_widget"]') && setIsZendeskOpen(!isZendeskOpen);
  };

  useEffect(() => {
    if (isZendeskOpen) {
      window.zE("messenger", "open");
    } else {
      window.zE("messenger", "close");
    }
  }, [isZendeskOpen]);

  useEffect(() => {
    const handleMessengerOpen = () => {
      setIsZendeskOpen(false);
      window.zE("messenger", "close");
    };

    window.zE("messenger:on", "close", handleMessengerOpen);
    window.zE("messenger:on", "unreadMessages", (value) => {
      setUnreadCount(value ?? 0);
    });

    return () => {
      window.zE("messenger", "close");
    };
  }, []);

  return (
    <>
      <button className={styles.zenDesk} onClick={handleClickZD} aria-label="Support button">
        {!!unreadCount && <span className={styles.iconUnread}>{unreadCount}</span>}
        <AnimatePresence exitBeforeEnter>
          <motion.span
            key={`${isZendeskOpen}`}
            custom={isZendeskOpen}
            initial="closed"
            exit="closed"
            animate="open"
            variants={variantsButton}
            transition={{
              duration: 0.15,
            }}
            className={styles.icon}
          >
            {isZendeskOpen ? <IconArrow /> : <IconZendesk />}
          </motion.span>
        </AnimatePresence>
      </button>
    </>
  );
};
