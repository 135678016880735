import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import styles from "./StyledSelect.module.scss";

import IconCheck from "./icons/icon-check.svg";

import { Select } from "@mui/material";
import IconClose from "./icons/icon-close.svg";
import { BootstrapInput, dropdownMenuProps } from "./StyledSelect.data";
import IconGlobe from "public/img/icon-globe.svg";
import SelectorInputSearch from "./SelectorInputSearch/SelectorInputSearch";
import { useRouter } from "next/router";
import { useScrollLock } from "../../../utils/hooks/useScrollLock";
import { changeLanguage } from "utils/changeLanguage";
import Link from "next/link";

const capitalize = (str: string): string => {
  if (!str) {
    return "";
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

const availableLanguages: {
  [key: string]: { order: number; value: string };
} = {
  en: { order: 1, value: "English" },
  es: { order: 2, value: "Español" },
  de: { order: 3, value: "Deutsche" },
  fr: { order: 4, value: "Français" },
  tr: { order: 5, value: "Türkçe" },
  ru: { order: 6, value: "Русский" },
  uk: { order: 7, value: "Українська" },
  zh: { order: 8, value: "中文" },
  uz: { order: 9, value: "O'zbekcha" },
  kk: { order: 10, value: "Қазақша" },
  pl: { order: 11, value: "Polski" },
  ar: { order: 12, value: "العربية" },
  ko: { order: 13, value: "한국어" },
  ja: { order: 14, value: "日本語" },
  pt: { order: 15, value: "Português" },
  fa: { order: 16, value: "فارسی" },
  pa: { order: 17, value: "ਪੰਜਾਬੀ" },
};

const getLanguageFullName = (code: string): string => {
  const languageName = availableLanguages[code].value;

  return capitalize(languageName || "");
};

const Image = styled.img`
  width: 24px;
  height: 24px;
`;

const Text = styled.span`
  color: var(--primary);
`;

export interface SelectListItem {
  value: string;
  title: string;
  icon?: string;
  hex?: string;
  hex_dark?: string;
  children?: SelectListItem[];
}

interface ILanguageSelect {
  size?: "default" | "small";
  color?: string;
  backgroundColor?: string;
  type?: "widget" | "auth";
}

const LanguageSelectSecond = ({
  color,
  backgroundColor,
  size,
  type,
  ...props
}: ILanguageSelect) => {
  const { t } = useTranslation("common");
  const [value, setValue] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { lockScroll, unlockScroll } = useScrollLock();

  const router = useRouter();

  const languages = useMemo(() => {
    return Object.keys(availableLanguages).sort(
      (a, b) => availableLanguages[a].order - availableLanguages[b].order,
    );
  }, [router]);

  const filteredOptionsList =
    searchValue !== ""
      ? languages.filter((elem) =>
          getLanguageFullName(elem).toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()),
        )
      : languages;

  const handleClear = (): void => {
    setValue("en");
  };

  const change = (lng: string) => {
    setValue(lng);
    // i18n?.changeLanguage(lng);
    changeLanguage(lng);
    setIsOpen(!isOpen);
    // dayjs.locale(lng);
    // handleChange(lng);
  };

  useEffect(() => {
    if (isOpen) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [isOpen, lockScroll, unlockScroll]);

  return (
    <div className={styles.lng_wrapper}>
      <button
        id="language__button"
        aria-label="language-selector"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <IconGlobe />
      </button>
      <Select
        open={isOpen}
        value={value}
        MenuProps={dropdownMenuProps}
        onClose={() => {
          setSearchValue("");
          setIsOpen(false);
        }}
        displayEmpty
        startAdornment={
          value?.length ? (
            <button
              className={styles.clear__button}
              onClick={() => handleClear()}
              aria-label="Clear"
            >
              <div className={styles.clear__button_iconWrapper}>
                <IconClose className={styles.icon_close} />
              </div>
            </button>
          ) : null
        }
        input={<BootstrapInput className={styles.inputWrapper} />}
        {...props}
      >
        <div className={styles.inputSearchWrapper}>
          <SelectorInputSearch
            key="search"
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            placeholder={t("header.Search")}
          />
        </div>
        {/* <div className={styles.selectList__bg}></div> */}
        <div className={styles.selectList}>
          {filteredOptionsList.length > 0 ? (
            filteredOptionsList.map((lang: string) => {
              return (
                <div key={lang} className={styles.element} onClick={() => change(lang)}>
                  <Link href={router.asPath} locale={lang} className={styles.link__item}>
                    <div className={styles.link__lang}>
                      <div className={styles.link__flag}>
                        <Image
                          src={`/img/flag-${lang}.svg`}
                          alt={`flag-${lang}`}
                          height={24}
                          width={24}
                        />
                      </div>
                      {getLanguageFullName(lang)}
                    </div>
                    <div>{lang === router.locale && <IconCheck />}</div>
                  </Link>
                </div>
              );
            })
          ) : (
            <p className={styles.notFoundText}>{t("header.Not found")}</p>
          )}
        </div>
      </Select>
    </div>
  );
};

export default LanguageSelectSecond;
