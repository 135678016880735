import { AnimatePresence, motion } from "framer-motion";
import React, {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  MouseEvent,
  SetStateAction,
  useCallback,
  useRef,
} from "react";
import IconClear from "./icons/close-rounded.svg";
import IconSearch from "./icons/search.svg";
import styles from "./SelectorInputSearch.module.scss";

const variantsClearIcon = {
  open: { opacity: 1, x: 0 },
  close: { opacity: 0, x: 20 },
};

interface ISelectorInputSearch {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  placeholder: string;
}

const SelectorInputSearch = ({
  searchValue,
  setSearchValue,
  ...props
}: ISelectorInputSearch): JSX.Element => {
  const searchRef = useRef<HTMLInputElement>(null);

  const focusInput = (e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    searchRef.current?.focus();
  };

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
      searchRef.current?.focus();
    },
    [setSearchValue],
  );

  const handleClearValue = () => {
    setSearchValue("");
  };

  return (
    <div
      className={styles.input__wrapper}
      onClick={focusInput}
      onKeyDown={focusInput}
      role="searchbox"
      tabIndex={0}
    >
      <div className={styles.icon_search}>
        <IconSearch />
      </div>
      <input
        ref={searchRef}
        className={styles.input}
        value={searchValue}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => e.stopPropagation()}
        onChange={handleChange}
        {...props}
      />
      <div className={styles.icon_clear_wrapper}>
        <AnimatePresence mode="wait">
          {searchValue.length > 0 && (
            <motion.span
              initial="close"
              animate="open"
              exit="close"
              variants={variantsClearIcon}
              className={styles.icon_clear}
              transition={{ duration: 0.1, bounce: false }}
            >
              <IconClear onClick={handleClearValue} />
            </motion.span>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default SelectorInputSearch;
