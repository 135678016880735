import Cookies from "js-cookie";

const domain_name = "cryptomus.com";

//!!!!ACHTUNG!!!
//Это ГЛОБАЛЬНАЯ смена языка, она унифицирована в каждом приложении
//!!!Если меняешь здесь - поменяй во всех сервисах тоже!!!
//test

export const changeLanguage = (language: string) => {
  const expirationDate = new Date();

  expirationDate.setFullYear(expirationDate.getFullYear() + 10);

  if (window.location.host.includes(domain_name)) {
    Cookies.set("i18next", language, { domain: domain_name, expires: expirationDate });
  } else {
    Cookies.set("i18next", language, { expires: expirationDate });
  }
};
