import React from "react";
import Image from "next/image";

import { Theme } from "utils/themeContextWrapper";

interface IAppStoreBtnProps {
  locale: string;
  theme: Theme;
  className?: string;
}

const acceptLocales = [
  "ar",
  "de",
  "en",
  "es",
  "fr",
  "ja",
  "ko",
  "pa",
  "pl",
  "pt",
  "ru",
  "tr",
  "zh",
];

const AppStoreBtn: React.FC<IAppStoreBtnProps> = ({ locale, theme, className }) => {
  const curLocale = acceptLocales.includes(locale) ? locale : "en";

  return (
    <a
      className={className}
      href="https://apps.apple.com/app/cryptomus/id6464404665"
      target="_blank"
      rel="noreferrer"
    >
      <Image
        src={`/img/AppStoreBtn/icon-app-${curLocale}-${theme === "dark" ? "light" : "dark"}.svg`}
        width={120}
        height={40}
        alt="Download on AppStore"
        loading="lazy"
      />
    </a>
  );
};

export default AppStoreBtn;
