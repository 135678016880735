import React from "react";
import IconLReddit from "public/img/footer-reddit.svg";
import IconTelegram from "public/img/footer-telegram.svg";
import IconTwitter from "public/img/footer-twitter.svg";
import IconYoutube from "public/img/footer-youtube.svg";
import IconLinkedin from "public/img/footer-linkedin.svg";
import IconInstagram from "public/img/footer-instagram.svg";
import IconFacebook from "public/img/footer-facebook.svg";
import IconDiscord from "public/img/footer-discord.svg";

export interface IFooterColumnItem {
  name: string;
  link: string;
}

export interface IFooterItem {
  title: string;
  items: IFooterColumnItem[];
}

export interface ISocialLinks {
  label: string;
  icon: JSX.Element;
  href: string;
}

export const socialLinks: ISocialLinks[] = [
  { label: "Telegram", icon: <IconTelegram />, href: "tg://resolve?domain=cryptomus" },
  { label: "Twitter", icon: <IconTwitter />, href: "https://x.com/cryptomus" },
  { label: "Reddit", icon: <IconLReddit />, href: "https://www.reddit.com/user/Cryptomuscom/" },
  { label: "Youtube", icon: <IconYoutube />, href: "https://www.youtube.com/@cryptomus" },
  { label: "Linkedin", icon: <IconLinkedin />, href: "https://www.linkedin.com/company/93083371" },
  { label: "Facebook", icon: <IconFacebook />, href: "https://www.facebook.com/cryptomus" },
  {
    label: "Instagram",
    icon: <IconInstagram />,
    href: "https://instagram.com/cryptomus",
  },
  {
    label: "Discord",
    icon: <IconDiscord />,
    href: "https://discord.gg/9vHK6R9HkQ",
  },
];

export const footerList: IFooterItem[] = [
  {
    title: "Company",
    items: [
      {
        name: "Home",
        link: "/gateway",
      },
      {
        name: "Tariffs",
        link: "/tariffs",
      },
      // {
      //   name: "Bug bounty",
      //   link: "/bug-bounty",
      // },
      {
        name: "Roadmap",
        link: "/roadmap",
      },
      {
        name: "Brand guideline",
        link: "/brand-guideline",
      },
      {
        name: "Blog",
        link: "/blog",
      },
      {
        name: "FAQ",
        link: "/faq/getting-started",
      },
      {
        name: "Contacts",
        link: "/contacts",
      },
    ],
  },
  {
    title: "Personal use",
    items: [
      {
        name: "Wallet",
        link: "/wallet",
      },
      {
        name: "P2P Exchange",
        link: "https://p2p.cryptomus.com",
      },
      {
        name: "Staking",
        link: "/staking",
      },
      {
        name: "Converter",
        link: "/convert",
      },
      {
        name: "Earn",
        link: "/earn",
      },
      {
        name: "Explorer",
        link: "/explorer",
      },
    ],
  },
  {
    title: "Business use",
    items: [
      {
        name: "White Label",
        link: "/white_label",
      },
      {
        name: "Crypto Processing",
        link: "/processing",
      },
      {
        name: "E-commerce plugins",
        link: "/plugins",
      },
      {
        name: "API",
        link: "https://doc.cryptomus.com/",
      },
    ],
  },
  {
    title: "Support",
    items: [
      {
        name: "Telegram",
        link: "tg://resolve?domain=cryptomus_support_bot",
      },
      {
        name: "Email",
        link: "mailto:support@cryptomus.com",
      },
    ],
  },
];
