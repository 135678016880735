import { api } from "./../pages/_app";

export interface IBasicResponseData<T> {
  state?: number;
  result: T;
}

interface IResult {
  geo: string;
  isError?: boolean;
}

export const SettingsService = {
  async getGeoAxios() {
    const geo = await api.get<IBasicResponseData<IResult>>("v1/fingerprint/geo");

    const result = await geo.data;

    return result;
  },
};
