import React, { useEffect, useState, useContext, createContext } from "react";
import Cookies from "js-cookie";

export type Theme = "light" | "dark";

const domain_name = "cryptomus.com";

const setThemeCookie = (theme: Theme) => {
  if (window.location.host.includes("cryptomus.com")) {
    Cookies.set("theme", theme, {
      expires: 400,
      domain: domain_name,
    });
  } else {
    Cookies.set("theme", theme, {
      expires: 400,
    });
  }
};

const getCookiesTheme = (): string | undefined => {
  return Cookies.get("theme") as Theme;
};

const ThemeContext = createContext<{
  theme: Theme;
  changeTheme: (theme: Theme) => void;
  toggleTheme: () => void;
}>(null!);

export const useThemeContext = () => {
  return useContext(ThemeContext);
};

export default function ThemeContextWrapper(props) {
  const [theme, setTheme] = useState<Theme>("light");

  const changeTheme = (theme): void => {
    setTheme(theme);
    setThemeCookie(theme);
  };

  const toggleTheme = (): void => {
    if (theme === "dark") {
      changeTheme("light");
    } else {
      changeTheme("dark");
    }
  };

  useEffect(() => {
    const cookieTheme = getCookiesTheme(); //Берем из кук тему

    if (cookieTheme) {
      setTheme(cookieTheme as Theme); //Устанавливаем тему, взятую из кук

      if (theme === "dark") {
        document.body.classList.add("dark");
        document.body.classList.remove("light");
      } else if (theme === "light") {
        document.body.classList.add("light");
        document.body.classList.remove("dark");
      }
    } else {
      const isPreferDarkScheme = window.matchMedia("(prefers-color-scheme: dark)").matches; //Проверяем, стоит ли у пользователя черная тема на устройстве

      if (isPreferDarkScheme) {
        setTheme("dark");
        document.body.classList.add("dark");
        document.body.classList.remove("light");
      } else {
        setTheme("light");
        document.body.classList.add("light");
        document.body.classList.remove("dark");
      }
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, changeTheme, toggleTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
}
