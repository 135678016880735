import React, { ReactNode } from "react";
import localFont from "next/font/local";

const Golos = localFont({
  src: [
    {
      path: "../../public/fonts/Golos-Text/Golos-Text_Regular.woff",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../public/fonts/Golos-Text/Golos-Text_Black.woff",
      weight: "900",
      style: "normal",
    },
    {
      path: "../../public/fonts/Golos-Text/Golos-Text_Bold.woff",
      weight: "700",
      style: "normal",
    },
    {
      path: "../../public/fonts/Golos-Text/Golos-Text_DemiBold.woff",
      weight: "600",
      style: "normal",
    },
    {
      path: "../../public/fonts/Golos-Text/Golos-Text_Medium.woff",
      weight: "500",
      style: "normal",
    },
    {
      path: "../../public/fonts/Golos-Text/Golos-Text_Regular.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../public/fonts/Golos-Text/Golos-Text_Black.woff2",
      weight: "900",
      style: "normal",
    },
    {
      path: "../../public/fonts/Golos-Text/Golos-Text_Bold.woff2",
      weight: "700",
      style: "normal",
    },
    {
      path: "../../public/fonts/Golos-Text/Golos-Text_DemiBold.woff2",
      weight: "600",
      style: "normal",
    },
    {
      path: "../../public/fonts/Golos-Text/Golos-Text_Medium.woff2",
      weight: "500",
      style: "normal",
    },
  ],
});

const Fonts: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <div className={Golos.className}>{children}</div>;
};

export default Fonts;
