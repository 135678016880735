import styles from "./BurgerMenu.module.scss";

import React, { useState } from "react";
import { Translation, useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";

import cn from "classnames";
import { AnimatePresence, motion } from "framer-motion";

import IconArrow from "public/img/accordion-arrow-down.svg";
import IconClose from "public/img/burger-icon-close.svg";
import { useAuth } from "services/auth.service";
import { Button } from "components/shared/Button/Button";
import Logo from "components/shared/Logo/Logo";
import { IServiceList, IServiceItem } from "components/Layout/Header/Header";
import { getLocaleLink } from "../../utils/getLocaleLink";

const variants = {
  closed: {
    height: 0,
  },
  open: {
    height: "auto",
  },
};

interface IBurgerMenuProps {
  setOpenBurger: React.Dispatch<React.SetStateAction<boolean>>;
  openBurger: boolean;
  serviceList: IServiceList;
  ServiceItem: ({ service }: IServiceItem) => JSX.Element;
}

interface IServiceDropdownProps {
  serviceList: IServiceList;
  ServiceItem: ({ service }: IServiceItem) => JSX.Element;
  isOpen: boolean;
}

const ServiceDropdown: React.FC<IServiceDropdownProps> = ({ serviceList, ServiceItem, isOpen }) => {
  const { t } = useTranslation(["common"]);
  const [personalOpen, setPersonalOpen] = useState<boolean>(false);
  const [businessOpen, setBusinessOpen] = useState<boolean>(false);

  const types = [
    {
      title: "Personal use",
      action: setPersonalOpen,
      isOpen: personalOpen,
      serviceList: serviceList.personal,
    },
    {
      title: "Business use",
      action: setBusinessOpen,
      isOpen: businessOpen,
      serviceList: serviceList.business,
    },
  ];

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        animate={isOpen ? "open" : "closed"}
        exit="closed"
        initial="closed"
        variants={variants}
        transition={{ duration: 0.3 }}
        className={styles.types_container}
      >
        {types.map((type) => (
          <div className={styles.type} key={type.title}>
            <div className={styles.type_title} onClick={() => type.action((prev) => !prev)}>
              <p>{t(`header.${type.title}`)}</p>
              <div className={cn(styles.icon, { [styles.open]: type.isOpen })}>
                <IconArrow />
              </div>
            </div>
            <AnimatePresence exitBeforeEnter>
              <motion.div
                animate={type.isOpen ? "open" : "closed"}
                exit="closed"
                initial="closed"
                variants={variants}
                transition={{ duration: 0.3 }}
                className={styles.types_container}
              >
                <ul>
                  {type.serviceList.map((service) => (
                    <ServiceItem service={service} key={service.title} />
                  ))}
                </ul>
              </motion.div>
            </AnimatePresence>
          </div>
        ))}
      </motion.div>
    </AnimatePresence>
  );
};

const BurgerMenu = ({
  setOpenBurger,
  openBurger,
  serviceList,
  ServiceItem,
}: IBurgerMenuProps): JSX.Element => {
  const { isAuth } = useAuth();
  const [servicesOpen, setServicesOpen] = useState<boolean>(false);

  const router = useRouter();
  const { locale } = router;

  return (
    <Translation>
      {(t) => (
        <div
          className={cn(styles.burger__wrapper, {
            [styles.active]: openBurger,
          })}
        >
          <div className={cn(styles.burger__content)}>
            <div className={styles.header}>
              <Logo />
              <IconClose className={styles.icon_close} onClick={() => setOpenBurger(false)} />
            </div>
            <div className={cn(styles.burger__menu)}>
              <div className={styles.menu_item} onClick={() => setServicesOpen((prev) => !prev)}>
                <p>{t("header.Services")}</p>
                <div className={cn(styles.icon, { [styles.open]: servicesOpen })}>
                  <IconArrow />
                </div>
              </div>
              <ServiceDropdown
                serviceList={serviceList}
                ServiceItem={ServiceItem}
                isOpen={servicesOpen}
              />
              <Link href="/tariffs" onClick={() => setOpenBurger(false)}>
                {t("header.Tariffs")}
              </Link>
              <Link href="/blog" onClick={() => setOpenBurger(false)}>
                {t("header.Blog")}
              </Link>
              <Link
                href={getLocaleLink("https://doc.cryptomus.com/", locale)}
                target="_blank"
                rel="noreferrer"
              >
                API
              </Link>
            </div>
          </div>

          <div className={styles.burger__buttons}>
            {isAuth ? (
              <Button
                appearance="primary"
                fullWidth
                linkProps={{
                  type: "external",
                  href: getLocaleLink("https://app.cryptomus.com/overview", locale),
                }}
              >
                {t("Go to dashboard")}
              </Button>
            ) : (
              <>
                <Button
                  appearance="secondary"
                  fullWidth
                  linkProps={{
                    type: "external",
                    href: getLocaleLink("https://app.cryptomus.com/login", locale),
                  }}
                >
                  {t("header.Login")}
                </Button>
                <Button
                  appearance="primary"
                  fullWidth
                  linkProps={{
                    type: "external",
                    href: getLocaleLink("https://app.cryptomus.com/signup", locale),
                  }}
                >
                  {t("Sign Up")}
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </Translation>
  );
};

export default BurgerMenu;
